<template>
  <div>
    <div class="button-create-pickup" v-show="tabIndex === 3">
        <b-button variant="warning" class="push-right"><feather-icon icon="PlusIcon"/> Añadir courier </b-button>
      </div>
    <div class="authorized-carrier-button" v-show="tabIndex === 4">
      <b-button variant="warning" class="push-right" @click="() => openModal()" ><feather-icon icon="PlusIcon" />Autorizar Courier</b-button >
    </div>
    <b-tabs align="left" class="tab-primary" v-model="tabIndex">
      <b-tab>
        <template #title>
          <div class="tab-title">
              <span> {{$t('Info de la empresa')}} </span>
          </div>
        </template>
        <div class="row">
        <shipper-info></shipper-info>
        </div>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="tab-title">
            <span> {{$t('Mis cuentas')}} </span>
          </div>
        </template>
        <multi-accounts :openModal="openModal"></multi-accounts>
        <modal-create-account :formTab="form"></modal-create-account>
      </b-tab>
      <b-tab>
        <template #title>
          <div class="tab-title">
              <span> {{$t('Bodegas')}} </span>
          </div>
        </template>
        <generic-addresses :owner="shipper" addressType="warehouse"/> <!-- v-if="currentShipper.id" -->
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="tab-title">
              <span> {{$t('Courier / Configuración')}} </span>
          </div>
        </template>
        <authorized-carriers :openModal="openModal"></authorized-carriers>
        <modal-authorized-carriers-shipper :formTab="form"></modal-authorized-carriers-shipper>
        <authorized-carriers :openModal="openModal"></authorized-carriers>
      </b-tab>
      <b-tab v-if="true">
        <template #title>
          <div class="tab-title">
              <span> {{$t('Marketplace')}} </span>
          </div>
        </template>
      </b-tab>
      <b-tab v-if="true">
        <template #title>
          <div class="tab-title">
              <span> {{$t('Integraciones')}} </span>
          </div>
        </template>
      </b-tab>
      <b-tab v-if="true">
        <template #title>
          <div class="tab-title">
              <span> {{$t('Avanzado')}} </span>
          </div>
        </template>
      </b-tab>
      <b-tab v-if="true">
        <template #title>
          <div class="tab-title">
              <span> {{$t('Notificaciones')}} </span>
          </div>
        </template>
      </b-tab>
      <b-tab  v-if="true">
        <template #title>
          <div class="tab-title">
              <span> {{$t('Campos adicionales')}} </span>
          </div>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ShipperInfo from './ShipperInfo.vue'
import GenericAddresses from '@/views/components/custom/generic-addresses/GenericAddresses.vue'
import AuthorizedCarriers from './AuthorizedCarriersShipper.vue'
import ModalAuthorizedCarriersShipper from './ModalAuthorizedCarriersShipper.vue'
import MultiAccounts from './MultiAccounts.vue'
import ModalCreateAccount from './Multi-Account/ModalCreateAccount.vue'

export default {
  name: 'tabs',
  components: { ShipperInfo,  GenericAddresses, AuthorizedCarriers, ModalAuthorizedCarriersShipper, MultiAccounts,  ModalCreateAccount },

  data () {
    return {
      form: {},
      tabIndex: 0,
      loading: {
        first: true
      }
    }
  },

  mounted() {
    this.getAllInitialData()
  },

  computed: {
    ...mapGetters({
      shipper: 'getShipper'
    })
  },
  watch: {
    tabIndex () {
      this.form = {}
    }
  },
  methods: {
    getAllInitialData () {
      const arrServices = []
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
        if (this.loading.first) this.$store.dispatch('fetchService', { name: 'getOrganizations', queryParams: { simplified: true }})
        this.getShipper()
        this.loading.first = false
      } else {
        this.$alert(this.$t('msg-error-permission'))
        return null
      }
    },
    getShipper () {
      const params = { shipper_id: this.$route.params.id }
      this.$store.dispatch('fetchShipper', params)
    },
    openModal(data) {
      if (data) this.form = data
      else this.form = {}
      switch (this.tabIndex) {
      case 1:
        this.$bvModal.show('modalCreateaccount')
        break
      case 2:
        break
      case 4:
        this.$bvModal.show('modalAuthorizedCarrierShipper')
        break
      default:
        break
      }
    }
  }
}
</script>
